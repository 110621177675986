<template>
  <section class="cont douyin-message-box">
     <!-- 面包屑  -->
     <el-row class="crumbs-box">
        <el-breadcrumb separator-class="el-icon-arrow-right">
           <el-breadcrumb-item>营销云</el-breadcrumb-item>
           <el-breadcrumb-item>运营</el-breadcrumb-item>
           <el-breadcrumb-item to="/activity_list/DOUYIN_MESSAGE">抖音私信</el-breadcrumb-item>
           <el-breadcrumb-item>创建抖音私信</el-breadcrumb-item>
        </el-breadcrumb>
        <el-button  class="bg-gradient"  @click="handledraft()">保存草稿</el-button>
     </el-row>
     <!-- 返回页头 -->
     <el-page-header @back="goBack" content="创建抖音私信"></el-page-header>
     <el-row class="cont-form-box">
        <!-- 步骤条 -->
           <el-steps :active="active" simple>
             <el-step title="01.填写基本信息，包含私信名称发送时间，推送方式等"></el-step>
             <el-step title="02.选择接收人群"></el-step>
             <el-step title="03.填写实现发送内容"></el-step>
           </el-steps>
       <!--第一步-->
       <el-row class="content">
         <el-row class="step-cont step1-cont" v-if="active === 0">
           <el-row class="input-box">
             <el-col :span="2" class="input-name"><i class="asterisk">*</i>计划名称</el-col>
             <el-col :span="9" class="m-left-10">
               <el-input  class="width-300" v-model="eventName"></el-input>
             </el-col>
           </el-row>

           <el-row class="input-box">
             <el-col :span="2" class="input-name"><i class="asterisk" >*</i>推送方式</el-col>
             <el-col :span="9" class="m-left-10">
               <el-radio v-model="pushRule" label="ONCE">立即发送</el-radio>
             </el-col>
           </el-row>

           <el-row class="input-box">
             <el-col :span="2" class="input-name"><i class="asterisk" style="opacity: 0;" >*</i></el-col>
             <el-col :span="9" class="m-left-10">
               <el-radio v-model="pushRule" label="ONCEtow">定时发送</el-radio>
               <el-date-picker
                   v-if="pushRule == 'ONCEtow' "
                   style="width: 11.6rem"
                   v-model="timeCycle"
                   :picker-options="pickerOptions"
                   @change="handchangeDate"
                   type="datetime"

                   placeholder="选择日期时间">
               </el-date-picker>
             </el-col>
           </el-row>

           <el-row class="input-box">
             <el-col :span="2" class="input-name"><i class="asterisk" style="opacity: 0" >*</i></el-col>
             <el-col :span="20" class="m-left-10">
               <el-radio v-model="pushRule" label="PERIODIC">循环推送</el-radio>
               <el-col v-if="pushRule == 'PERIODIC' ">
                 <el-date-picker
                     v-model="activityTime"

                     class="width-220 m-right-10"
                     @change="choosetime()"
                     :picker-options="pickerOptions"
                     type="daterange"
                     range-separator="至"
                     start-placeholder="开始日期"
                     end-placeholder="结束日期"
                     value-format="yyyy-MM-dd">
                 </el-date-picker>
                 <el-radio v-model="ruleOption" label="NEW_USERS">只推新增用户</el-radio>
                 <el-radio v-model="ruleOption" label="ALL_USERS">计划内所有用户</el-radio>
                 <cron @change="changeCron" v-model="cronExpression" @close="cronPopover = false" i18n="cn"></cron>

               </el-col>

             </el-col>
           </el-row>


           <!--        <el-row class="input-box">-->
           <!--          <el-col :span="2" class="input-name"><i class="asterisk" style="opacity: 0">*</i></el-col>-->
           <!--          <el-col :span="9" class="m-left-10">-->
           <!--            <el-radio v-model="pushtype" label="BE_FOCUSED">加粉后立即推送</el-radio>-->
           <!--          </el-col>-->
           <!--        </el-row>-->

           <!--        <el-row class="input-box">-->
           <!--          <el-col :span="2" class="input-name"><i class="asterisk" style="opacity: 0" >*</i></el-col>-->
           <!--          <el-col :span="9" class="m-left-10">-->
           <!--            <el-radio v-model="pushtype" label="4">关注后立即推送</el-radio>-->
           <!--          </el-col>-->
           <!--        </el-row>-->

           <el-row class="input-box">
             <el-col :span="2" class="input-name">转化跟踪</el-col>
             <el-col :span="9" class="m-left-10">
               <el-switch v-model="isConvert" active-value="YES" inactive-value="NO"  active-color="#1489e2" inactive-color="#c5ced8"></el-switch>
             </el-col>
           </el-row>

           <el-row class="input-box" v-if="isConvert === 'YES'">
             <el-col :span="2" class="input-name">转化周期</el-col>
             <el-col :span="9" class="m-left-10">
               <el-input v-model="days"  class="width-140"></el-input>&nbsp;天内
             </el-col>
           </el-row>
         </el-row>

         <!-- 第二步-->

         <el-row class="targetuser" v-show="active == 1">
           <el-row style="height: 100%;float: left">
             <el-row class="target">目标用户</el-row>
             <el-row class="borders">
               <span>和</span>
             </el-row>
           </el-row>
           <el-row style="width: 20%; float: left">
             <el-row style="width: 100%">
               <el-row v-for="(tablist,index) in multipleclusters" :key="index" style="width:100%; text-align:right; display: flex">
                 <el-select v-model="tablist.id" :disabled="tablist.check"  placeholder="请选择目标用户" @change="selectgroup(tablist,index)">
                   <el-option
                       v-for="item in usergroup"
                       :key="item.id"
                       :label="item.groupName"
                       :value="item.id"
                       :disabled="item.check"
                   >
                   </el-option>
                 </el-select>
                 <i class="el-icon-error" @click="deleteObject(tablist,index)" v-show="multipleclusters.length != 0 " style="margin-top: 10px"></i>
               </el-row>
             </el-row>
           </el-row>


           <el-row class="add">
             <el-button class="buttons" :disabled="btnDisabled" type="primary"  plain @click="addAgroup()">添加分群</el-button>
             <el-popover
                 placement="top-start"
                 title="目标用户"
                 width="200"
                 trigger="hover"
                 content="活动的目标受众，最终收到触达消息的人群分组。可以选择系统默认的所有用户、新访问用户，也可以选择方舟智能分析中创建的用户分群或者是通过 API 对接的任何第三方用户分组信息。"
             >
               <el-button slot="reference" class="ioc"
               ><i class="el-icon-question"></i
               ></el-button>
             </el-popover>
           </el-row>


           <el-row>

           </el-row>
           <el-row class="Thetotaluser">
             <el-row>总用户数</el-row>
             <el-row class="amount"><h1>{{ total }}</h1>   人</el-row>
           </el-row>
         </el-row>

         <!-- 第三步 -->

         <el-row class="step-cont step3-cont" v-if="active === 2">
           <el-row class="input-box" >
             <el-col :span="2" class="input-name">发送内容</el-col>
             <el-col :span="9" class="m-left-10">
               <el-radio v-model="sendCont" label="TEXT">文本</el-radio>
               <el-radio v-model="sendCont" label="IMG">图片</el-radio>
               <el-radio v-model="sendCont" label="VIDEO">视频</el-radio>
             </el-col>
           </el-row>
           <el-row class="input-box" v-if="sendCont =='TEXT' ">
             <el-col :span="2" class="input-name">发布内容</el-col>

             <!--文本内容-->

             <el-col :span="16" class="m-left-10"
                     v-if="sendCont === 'TEXT' "
             >
               <el-button  class="button-new-tag"  @click="handleInputConfirm">+ 粉丝昵称</el-button>

               <el-input
                   style="display: inline"
                   type="textarea"
                   :rows="3"
                   placeholder="请输入内容"
                   v-if="sendCont === 'TEXT' "
                   v-model="textarea"
               >
               </el-input>
             </el-col>
           </el-row>
           <!-- 上传素材-->
           <el-row class="input-box"  v-if="sendCont !='TEXT' ">
             <el-col :span="2" class="input-name"><i class="asterisk">*</i>选择素材</el-col>
             <el-col :span="9" class="m-left-10" @click.native="addmaterial()">
               <div class="uploadbox">
                 <p class="uploadicon">+</p>
                 <p>选择素材</p>
               </div>
             </el-col>
           </el-row>
           <!-- 回显图片-->
           <el-row class="input-box" v-if="datalist.length != 0 ">
             <el-col :span="2" class="input-name"><i class="asterisk" style="opacity: 0;">*</i></el-col>
             <el-col :span="9" class="m-left-10">
               <img :src="datalist[0].path" :onerror="errorImg01" style="width: 145px;height: 145px;cursor: pointer;" v-if="datalist[0].type === 'IMAGE' " />
               <video :src="datalist[0].path" style="width: 145px;height: 145px;cursor: pointer;"  controls v-if="datalist[0].type === 'VIDEO' " />
             </el-col>
           </el-row>

         </el-row>

         <Popup v-if="centerDialogVisible"
                @getdatalist="getdatalist"
                :types="types"
                :visible.sync="centerDialogVisible"
                ref="popup"></Popup>

         <el-row class="next-btn m-top-15">
           <el-button  type="primary" @click="active --" v-show="active !== 0">上一步</el-button>
           <el-button type="primary" class="m-left-30 bg-gradient" @click="nextStep" v-show="active !== 2">下一步</el-button>
           <el-button  type="primary" class="m-left-30" @click="handleSave()" v-show="active === 2">发送</el-button>
         </el-row>
       </el-row>
     </el-row>
  </section>
</template>

<script>
import { urlObj } from '@/api/interface'
import { operate } from  '@/api/interface/smtech'
import {label} from "@/api/interface/data";
import { cron } from 'vue-cron'
import Popup from  '@/components/local/Popup.vue'
import { mapState } from 'vuex'
export default {
  watch: {
    hotelInfo: {
        handler(newVal, oldVal) {
          if (newVal.id !== oldVal.id && oldVal) {
            this.hotelId = this.hotelInfo.id
            this.wechatlabel()
          }
        },
    }
  },
  computed: {
    ...mapState(['hotelInfo']),
  },
  components: {cron,Popup},
  data() {
    return {
      active: 0,
      id: 0,
      hotelId:'',
      code:'',
      companyId:'',
      errorImg01:'this.src="' + require('../../../../assets/advert_template_img.png') + '"',
      types:'',
      accessToken:'',
      memberCount: 0, //总用户数
      //防止选中过去的日期
      pickerOptions: {
        disabledDate(time) {
          //如果没有后面的-8.64e7就是不可以选择今天的
          return time.getTime() < Date.now() -1 * 24 * 3600 * 1000;
        },
      },
      /* 第一步 */
      eventName: '',    // 活动名称
      activityTime:[],//循环时间区间
      cronExpression:'',
      timeCycle: '',    // 定时推送时间
      isConvert: 'NO',  // 转化跟踪
      pushRule:'ONCE', //推送类型
      days: '',         // 转化周期
      targetId: '',     // 转化目标
      daynum:1,        //循环推送的天数
      hourtime:'',      //循环推送的时间
      beginDate:'',     //循环开始时间
      endDate:'',       //循环结束时间
      targetList: [{},],   // 转化目标列表
      /* 第二步 */
      bools:false,//删除标识
      groupId:'',//推送人ID
      multipleclusters:[],//发送人员列表
      usergroup:[],//推送可选择人列表
      total:0,
      ruleOption:'NEW_USERS',
      btnDisabled:false,//添加用户按钮标识
      isWaitSend: 'NO', // 发送规则
      /* 第三步 */
      limit: 0,
      dynamicTags:[],
      dialogImageUrl:'',
      oldPic:false,
      materialld:'',
      dialogvoiceUrl:'',
      dialogVisible:false,
      uploadpictureb:[],
      inputValue:"",
      textarea:"",
          // '尊敬的为回馈广大用户，为所有的女神们谋福利，本酒店决定，3月8日提供200套半价豪华大床房，恭喜您获得本次福利的机会，请您至我的首页进行福利获取。',
      texttemplate:"",
          // '尊敬的为回馈广大用户，为所有的女神们谋福利，本酒店决定，3月8日提供200套半价豪华大床房，恭喜您获得本次福利的机会，请您至我的首页进行福利获取。',
      page: 1,
      inputVisible: false,
      videoList: [],
      videoId: '',
      title: '',
      centerDialogVisible:false,
      datalist:[],//所选中素材列表
      sendCont: 'TEXT',
       accountType: ''
    }
  },
  created() {
    this.accessToken = sessionStorage.getItem('accessToken')
    if (this.$route.query.code){
      this.code = this.$route.query.code
      this.getdytoken()
    }
  },

  mounted() {
    this.companyId = sessionStorage.getItem('userInfo').tenantId
     this.accountType = JSON.parse(sessionStorage.getItem('userInfo') || '{}').accountType
    this.hotelId = this.hotelInfo.id
    this.limit = sessionStorage.getItem('pageSize') * 1
    // this.getVideoList()
    this.wechatlabel()
    this.action = this.$route.query.act
    this.action === 'edit' && this.getTiktokObj()

  },
  methods: {
    //时间格式转换
    formatDate (value) {
      let date = new Date(value);
      let y = date.getFullYear();
      let MM = date.getMonth() + 1;
      MM = MM < 10 ? ('0' + MM) : MM;
      let d = date.getDate();
      d = d < 10 ? ('0' + d) : d;
      let h = date.getHours();
      h = h < 10 ? ('0' + h) : h;
      let m = date.getMinutes();
      m = m < 10 ? ('0' + m) : m;
      let s = date.getSeconds();
      s = s < 10 ? ('0' + s) : s;
      return y + '-' + MM + '-' + d + ' ' + h + ':' + m + ':' + s;
    },
    //选择时间后
    choosetime(){
      this.beginDate = this.activityTime[0]
      this.endDate = this.activityTime[1]
    },
    //用户群组
    wechatlabel() {
      const url = label.tagGroup;
      const param = {
        hotelId: this.hotelId,
        // groupSource: "",
        groupSource: "DOUYIN",
        limit: this.limit,
        page: this.page,
      };
      this.$axios.post(url, param).then((res) => {
        this.usergroup = res.records;
        this.usergroup.forEach(item =>
            item.check = false
        )

      });
    },
    // 获取表达式内容
    changeCron(val){
      this.cronExpression = val
      console.log(val)
    },
    //新增下拉框
    addAgroup(){
      this.multipleclusters.push({})
      if (this.multipleclusters.length == this.usergroup.length || this.usergroup.length == 0){
        this.btnDisabled = true
      }
    },
    //选中的时候
    selectgroup(row,val){
      this.total = 0
      this.usergroup.map(i=>{
        this.multipleclusters.map(index=>{
          if (index.id == i.id){
            i.check = true
            row.check = true
            index.memberCount = i.memberCount
            this.total+= index.memberCount
          }
        })
      })
    },
    //插入粉丝昵称
    handleInputConfirm() {
      this.dynamicTags.push({name:'{name}'});
      let newlist = []
      this.dynamicTags.map(item =>{
        newlist.push(item.name)
      })
      if(newlist.length==1) {
        this.texttemplate = this.textarea.replace('{name}', '')
      }
      this.textarea =  this.texttemplate + newlist
    },

    //删除粉丝昵称
    // handleClose() {
    //   this.dynamicTags.splice(this.dynamicTags, 1);
    //   let newlist = []
    //   this.dynamicTags.map(item =>{
    //     newlist.push(item.name)
    //   })
    //   this.textarea = this.texttemplate.slice(0,3) + newlist + this.texttemplate.slice(3)
    // },

    //删除下拉框
    deleteObject(val,j){
      this.total = 0
      for (var i =0;i<this.usergroup.length;i++){
        if (this.usergroup[i].id == val.id){
          this.usergroup[i].check =false
        }
      }

       for (var z = 0;z<this.multipleclusters.length;z++){
          if (val.id == undefined){
             this.multipleclusters.splice(j,1)
             if (this.multipleclusters.length != this.usergroup.length || this.usergroup.length != 0){
                this.btnDisabled = false
             }
          }else if (val.id != undefined && this.multipleclusters[z].id == val.id)
             this.multipleclusters.splice(z,1)
          if (this.multipleclusters.length != this.usergroup.length || this.usergroup.length != 0){
             this.btnDisabled = false
          }
          this.total += this.multipleclusters[z].memberCount
       }
    },
    //防止选中过去的时间
    handchangeDate() {//当前时间1分钟后
      var startAt = new Date(this.timeCycle) * 1000 / 1000;
      if (startAt < Date.now()) {
        this.timeCycle = new Date() * 1 + 60 * 1000;
      }
    },
    // 获取抖音编辑信息
    getTiktokObj(){
      let tiktokObj = JSON.parse(sessionStorage.getItem('activityObj'))
      console.log(tiktokObj)
      this.id = tiktokObj.id
      this.eventName = tiktokObj.eventName
      this.isWaitSend = tiktokObj.isWaitSend
      this.timeCycle = tiktokObj.timeLong
      this.multipleclusters = tiktokObj.eventGroupRefList
      this.multipleclusters.map(item=>{
        item.id = item.groupId
      })
      this.total = tiktokObj.createOperator
      if (tiktokObj.pushRule == "ONCE" && tiktokObj.timeLong){
        this.pushRule ="ONCEtow"
      }else{
        this.pushRule = tiktokObj.pushRule
        this.endDate = tiktokObj.endDate
        this.beginDate = tiktokObj.beginDate
        this.hourtime = tiktokObj.createTime.slice(0,11) + tiktokObj.timeLong.slice(2)
      }
      if (tiktokObj.days){
        this.days = tiktokObj.days
      }
      // this.isWaitSend = tiktokObj.isWaitSend
      this.isConvert = tiktokObj.isConvert
      this.targetId = tiktokObj.targetId
      this.videoId = tiktokObj.content
    },
    // 保存发布
    handleSave(){
      let url = operate.createActivity
      let groupIds = []
      this.multipleclusters.map(item=>{
        groupIds.push(item.id)
      })
      groupIds = groupIds.join(',')
      let param = new FormData()
      //固定传值
      param.append('hotelId', this.hotelId) //酒店ID
      param.append('companyId', this.companyId)//所属ID
      param.append('eventName', this.eventName)//活动名称
      param.append('platform', 'DOUYIN_MESSAGE')//类型抖音私信
      param.append('isConvert', this.isConvert)//是否转换


      //立即发送时
      if (this.pushRule == "ONCE"){
        param.append('isWaitSend', 'NO')
        param.append('state', 'END')

      }
      //定时发送时
      if (this.pushRule == "ONCEtow"){
        this.pushRule = 'ONCE'
        param.append('isWaitSend','YES')//
        this.timeCycle = this.formatDate(this.timeCycle)
        param.append('timeLong', this.timeCycle )
        param.append('state', 'IN_PROGRESS')

      }
      //循环发送
      if (this.pushRule == 'PERIODIC'){
        param.append('state', 'IN_PROGRESS')
        this.timeCycle = this.daynum + '/' + this.formatDate(this.hourtime).slice(11)

        param.append('ruleOption', this.ruleOption )
        param.append('timeLong', this.cronExpression )

        param.append('beginDate', this.formatDate(this.endDate) )
        param.append('endDate', this.formatDate(this.endDate) )

        param.append('isWaitSend', 'NO')
        console.log(this.timeCycle)
      }
      //内容为文本时
      if (this.sendCont == 'TEXT'){
        param.append('eventDouyin.content', this.textarea)
      }else{
        param.append('eventDouyin.materialId', this.materialld)
      }
      //转化跟踪开启时
      param.append('groupIds', groupIds)
      if (this.isConvert === 'YES'){
        param.append('days', this.days)//转化周期
      }
      // param.append('groupIds', '107447955428933632')
      param.append('eventDouyin.type', this.sendCont)//推送方式
      param.append('pushRule', this.pushRule)//推送规则

      // param.append('targetId', this.targetId)
      //修改内容时
      if (this.action === 'edit') {
        url = operate.editActivity
        param.append('id', this.id)
      }
      this.$axios.post(url, param, 'file').then(res => {
        if (res.success) {
          this.$message({
            showClose: true,
            message: '创建成功！',
            type: 'success'
          })
          this.goBack()
        }
      }).catch(err => {
        let status = err.response.data.status
        if (status === 10007) this.getTiktokCode()
      })
    },
    //保存草稿
    handledraft(){
      let url = operate.createActivity
      let groupIds = []
      this.multipleclusters.map(item=>{
        groupIds.push(item.id)
      })
      groupIds = groupIds.join(',')
      let param = new FormData()
      //固定传值
      param.append('hotelId', this.hotelId) //酒店ID
      param.append('companyId', this.companyId)//所属ID
      param.append('eventName', this.eventName)//活动名称
      param.append('platform', 'DOUYIN_MESSAGE')//类型抖音私信
      param.append('isConvert', this.isConvert)//是否转换
      param.append('state', 'DRAFT')

      //立即发送时
      if (this.pushRule == "ONCE"){
        param.append('isWaitSend', 'NO')
      }
      //定时发送时
      if (this.pushRule == "ONCEtow"){
        this.pushRule = 'ONCE'
        param.append('isWaitSend','YES')//
        this.timeCycle = this.formatDate(this.timeCycle)
        param.append('timeLong', this.timeCycle )
      }
      //循环发送
      if (this.pushRule == 'PERIODIC'){
        this.timeCycle = this.daynum + '/' + this.formatDate(this.hourtime).slice(11)
        param.append('timeLong', this.timeCycle )

        param.append('beginDate', this.formatDate(this.beginDate) )
        param.append('endDate', this.formatDate(this.endDate) )
        param.append('isWaitSend', 'NO')
      }
      //内容为文本时
      if (this.sendCont == 'TEXT'){
        param.append('eventDouyin.content', this.textarea)
      }else{
        param.append('eventDouyin.materialId', this.materialld)
      }
      param.append('groupIds', groupIds)//推送人群
      //转化跟踪开启时
      if (this.isConvert === 'YES'){
        param.append('days', this.days)//转化周期
      }
      // param.append('groupIds', '107447955428933632')
      param.append('eventDouyin.type', this.sendCont)//推送方式
      param.append('pushRule', this.pushRule)//推送规则

      // param.append('targetId', this.targetId)
      if (this.action === 'edit') {
        url = operate.editActivity
        param.append('id', this.id)
      }
      this.$axios.post(url, param, 'file').then(res => {
        if (res.success) {
          this.$message({
            showClose: true,
            message: '创建成功！',
            type: 'success'
          })
          this.goBack()
        }
      }).catch(err => {
        let status = err.response.data.status
        if (status === 10007) this.getTiktokCode()
      })
    },
    // 获取上传的文件资源
    // handleChange(file){
    //   // 验证文件大小/格式
    //   const imgFormat = file.raw.type === 'image/jpeg' || file.raw.type === 'image/png' || file.raw.type === 'image/gif'  || file.raw.type === 'video/mp4'
    //   // const isLt2M = file.size / 1024 / 1024 < 2;
    //   if (!imgFormat) {
    //     this.$message.error('上传头像图片只能是 JPG 格式!')
    //     // 强制刷新局部DOM节点
    //     this.refresh = false
    //     this.$nextTick(() => {
    //       this.refresh = true
    //     })
    //     return
    //   }
    //   // if (!isLt2M) {
    //   //   this.$message.error('上传头像图片大小不能超过 2MB!');
    //   //   this.refresh = false
    //   //   this.$nextTick(() => {
    //   //     this.refresh = true
    //   //   })
    //   //   return
    //   // }
    //   this.oldPic = false
    //   this.file = file.raw
    //   this.file = file.raw
    //   const url = urlObj.uploadDetailFile;
    //   let param = new FormData();
    //   if(this.sendCont === 'IMG'){
    //     param.append('func','image')
    //     param.append('category','image')
    //   }
    //   if (this.sendCont === 'VIDEO'){
    //     param.append('func','voice')
    //     param.append('category','media')
    //   }
    //   param.append("file", this.file); // 文件
    //   param.append('bizId','default')
    //   param.append('module','event')
    //   param.append('menu','distributor')
    //
    //   param.append('isThumb',true)
    //   this.$axios.post_query(url, param, null, { "Content-Type": "multipart/form-data" })
    //       .then((res) => {
    //         console.log(res);
    //         this.materialld = res.records.id
    //         this.uploadpictures = res.records
    //         this.uploadpictureb.push(this.uploadpictures)
    //         if (file.raw.type ==='video/mp4' ){
    //           this.uploadpictureb.forEach(item=>{
    //             this.dialogvoiceUrl = window.getResourcesHost()+item.location+'/'+item.formatName
    //           })
    //         }else{
    //           this.uploadpictureb.forEach(item=>{
    //             item.imageUrl = window.getResourcesHost()+item.location+'/'+item.formatName
    //           })
    //         }
    //       });
    // },
    // // 点击图片查看大图
    // handlePictureCardPreview(file){
    //   this.dialogImageUrl = file.url;
    //   this.dialogVisible = true;
    // },
    // //清空上传组件的文件
    // restfile(){
    //     this.$refs.upload.clearFiles()
    //     this.uploadpictures = []
    //     this.dialogvoiceUrl = ""
    //
    // },
    // 下一步
    nextStep(){
      // 第一步
      if (!this.eventName) {
        this.$alert('活动名称不能为空！', '', {
          confirmButtonText: this.confirm,
          type: 'error'
        })
        return
      }
      if (this.pushRule == 'PERIODIC' && this.endDate ==""){
        this.$alert('活动时间不能为空！', '', {
          confirmButtonText: this.confirm,
          type: 'error'
        })
        return
      }
      // 第三步
      if (this.active === 3) {
        if (!this.videoId) {
          this.$alert('选择视频不能为空！', '', {
            confirmButtonText: this.confirm,
            type: 'error'
          })
          return
        }
      }
      this.active ++
    },
    //接收子组件传过来的值
    getdatalist(parmary){
      this.datalist = []
      this.datalist.push(parmary)
      this.types = this.datalist[0].type
      this.materialld = this.datalist[0].id
    },
    //显示素材库
    addmaterial(){
      this.centerDialogVisible = true
      if (this.sendCont == 'IMG'){
        this.types = 'IMAGE'
      }
      if (this.sendCont == 'VIDEO'){
        this.types = 'VIDEO'
      }
    },

    // 返回上一页
    goBack(){
      this.$router.go(-1)
    },
  }
}
</script>

<style lang="scss" scoped>
//.el-container .el-main .advert{ background-color: #ffffff }


.title-bar{
  line-height: 60px; padding: 0 20px; background-color: #f7f7f8;
  h2 { float: left;font-size: 18px;font-weight: normal }
  .btn{
    float: right;
  }
}
.step-box{
  border-bottom: 1px solid #e4e4e4;
  .step-wrapper{
    padding: 10px 0 10px 20px;
    /deep/ .el-step__title{ font-size: 12px }
  }
}
.content{
  font-size: 13px;
  .asterisk { color: red; margin-right: 3px }
  .input-name { text-align: right }
  .step-cont{ width: 830px; padding: 30px 0 0 40px }
  .step1-cont{
    .input-box{ line-height: 28px; margin-bottom: 20px }
    .input-box img video{
      width: 145px;
      height: 145px;
    }
    /deep/ .bottom button:nth-child(3){ display: none }
    /deep/  .bottom .value{opacity: 0}
  }
  .step2-cont{
    margin-bottom: 20px;
    .left { line-height: 28px }
    .right {
      .el-row {
        margin: 0 0 15px 10px;
        .el-select{ margin-bottom: 0 }
        .icon{ font-size: 18px; color: #999999; cursor: pointer }
      }
    }
  }
  .step3-cont{
    .input-box{ line-height: 28px; margin-bottom: 20px }
  }
  .step4-cont{
    .input-box{ line-height: 28px; margin-bottom: 20px }
    .input-name { color: #999999 }
  }
  .next-btn { margin: 40px 40px }
}
@media screen and (max-width: 1366px) {
  .advert {
    width: 1300px;
  }
}
.targetuser {
  width: 90%;
  margin: auto;
  margin-top: 2rem;
}
.borders {
  border-right: 1px solid #ebebeb;
  width: 20px;
  height: 50px;
  position: relative;
  float: left;
}
.borders span {
  display: inline-block;
  position: absolute;
  left: 12px;
  top: 13px;
  width: 20px;
  background: white;
  font-size: 14px;
}

.target {
  float: left;
  font-size: 14px;
  color: #a3a3a3;
  margin-top: 0.8rem;
}

.targetuser .el-row .el-select {
  margin-top: 0.3rem;
  margin-left: 1rem;
}
.add {
  width: 20%;
  display: flex;
  clear: both;
}
.add span {
  line-height: 60px;
}
.add .ioc {
  padding: 0px;
}
/deep/ .add .buttons {
  margin: 1rem 0px;
  width: 100%;
}
/deep/ .el-input__inner {
  background: #f5f5f5;
}
.Thetotaluser {
  margin-top: 2rem;
  margin-bottom: 1rem;
}
.Thetotaluser .amount {
  margin-top: 1rem;
  display: flex;
  line-height: 40px;
}
.Thetotaluser .amount h1{
  margin-right: 1rem;
}

.selectivetype {
  width: 50%;
  height: 100%;
  float: left;
  margin-left: 1rem;
  background: white;
  padding-left: 5rem;
  padding-top: 1rem;
}

.selectivetypes {
  margin-top: 2rem;
}
.selectivetypes span {
  font-size: 14px;
  margin-right: 1rem;
}
.el-textarea {
  width: 61%;
}
.videotitle .el-textarea {
  width: 38%;
}
.image-text .el-textarea {
  width: 62%;
  margin-top: 1rem;
}
.message {
  width: 100%;
  margin: auto;
  margin-top: 2rem;
}
.message span {
  display: inline-block;
  margin-right: 0.9rem;
  font-size: 14px;
}
.imag span {
  font-size: 14px;
  color: #999;
  line-height: 28px;
  margin-right: 1rem;
}

.upload-demo {
  width: 80px;
}
.imag {
  display: flex;
}

.video {
  margin-top: 2rem;
}
.videotitle {
  margin-top: 1rem;
}
.videotitle span {
  font-size: 14px;
  color: #999;
  margin-right: 1rem;
}
.videotitle .el-input {
  width: 285px;
}
.image-text {
  margin-top: 2rem;
}
.image-text .el-row span {
  font-size: 14px;
  color: #999;
  margin-right: 1rem;
}
.image-text .el-row .el-select {
  width: 465px;
}

.imga-title {
  margin-top: 1rem;
}
.imga-title .el-input {
  width: 465px;
}

.location {
  margin-top: 1rem;
}
.location span {
  font-size: 14px;
  color: #999;
}
.location .el-input {
  width: 465px;
}

.carte {
  margin-top: 2rem;
}
.carte .el-row {
  margin-top: 1rem;
}
.carte .el-row span {
  font-size: 14px;
  color: #999;
  margin-right: 1rem;
}

.menucontent {
  width: 72%;
  border: 1px solid #e5e5e5;
}
.menucontent .el-row .el-input {
  width: 83%;
}
.menucontent .el-row span {
  margin-left: 1rem;
  width: 25%;
  display: inline-block;
}

.deleted{
  color: red;
  text-align: center;
  width: 145px;
}
.uploadbox{
  width: 100px;
  height: 100px;
  background: #e4e7ed;
  border-radius: 5px;
  text-align: center;
  cursor: pointer;
}
.uploadicon{
  margin-top: 25px;
  border-radius: 100%;
  background: #dedede;
  width: 30px;
  height: 30px;
  line-height: 30px;
  display: inline-block;
  font-size: 18px;
  font-weight: 900;
}
</style>
