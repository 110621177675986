/* 储值卡 */
export const depositCard = {
   // 查询充值记录
   payRecords: '/member/depositCardPay/getPage',
   // 添加充值设置
   addCardSet: '/member/depositCard/add',
   // 编辑充值设置
   editCardSet: "/member/depositCard/edit",
   // 获取充值设置列表
   getCardSetList:"/member/depositCard/getPage",
   // 移动充值设置排序
   moveCardSet:"/member/depositCard/move",
   // 删除充值设置
   delCardSet:"/member/depositCard/delete",
   // 获取用户协议
   getUserProtocol:"/hotel/companyHotelCfg/get",
   // 修改用户协议
   editUserProtocol:"/hotel/companyHotelCfg/set",
}

/* 会员标签 */
export const label = {
   // 获取群组列表
   tagGroupList: '/member/getTagGroupList',
   // 获取群组列表
   tagGroup: '/member/getTagGroupPage',
   // 添加群组
   addTagGroup: '/member/addTagGroup',
   // 编辑群组
   editTagGroup: '/member/editTagGroup',
   // 删除标签群组
   delTagGroup: '/member/deleteTagGroup',
   // 预览群组
   previewTagGroup: '/member/previewTagGroup',
   // 获取会员列表
   memberListToGroup: '/member/getMemberListToGroup',
   // 查看群组使用酒店列表
   getHotelListByGroupId: '/member/tagGroupHotel/getHotelListByGroupId',
   // 获取个人标签列表
   membersTags: '/member/detail/getPage',
   // 会员绑定标签
   memberBindTags: '/member/memberTag/save',
   // 会员解绑标签
   memberUnbindTag: '/member/memberTag/remove',
   // 获取标签库列表
   tagLibrary: '/member/tagLibrary/select',
   // 添加/编辑标签库
   saveTagLibrary: '/member/tagLibrary/save',
   // 删除标签库
   delTagLibrary: '/member/tagLibrary/delete',
   // 保存自动化标签
   saveAutoTag: '/member/tagAutomation/save',
   // // 推送相关数据列表
   eventWechat:'/event/eventWechat/getList',
   //销售统计
   getSale:'/hadoop/order/getSale',
   //admin视角 订单数据
   getorderlist:'/hadoop/order/getList',
   //admin视角 酒店销售数据统计
   getHotelData:'/hadoop/hotelData/getHotelData',
   //admin视角 集团销售数据统计
   getGroupCompanyData:'/hadoop/company/getGroupCompanyData',
   //admin视角 租户销售数据
   getTenantData:'/hadoop/company/getTenantData',
   //集团酒店列表
   queryCompanyHotel:'/hadoop/company/queryCompanyHotel'
}

/* 会员画像 */
export const portrait = {
   // 获取个人画像
   memberStatistics: '/hadoop/getMemberStatisticsPage',
}

export const AgreementUnit = {
   //获取协议单位列表
   getAgreementPage:"/hotel/agreementUnit/getPage",
   //编辑协议单位
   updateAgreementUnit:"/hotel/agreementUnit/updateAgreementUnit",
   //新增协议单位
   addAgreementUnit:"/hotel/agreementUnit/addAgreementUnit",
   //获取管理人员列表
   getAgreementbyId:"/hotel/agreementUnitEmployee/get/",
   //删除协议单位
   deleteUnit:"/hotel/agreementUnit/deleteAgreementUnit/",
   //删除管理人员
   deleteEmployee:'/hotel/agreementUnitEmployee/delete',
   //添加管理人员
   addEmployee:"/hotel/agreementUnitEmployee/add",
}
