<template>
  <!-- 选择素材弹窗-->
  <el-dialog
      title="选择素材"
      :visible.sync="centerDialogVisible"
      :before-close="onsubmit"
      width="80%"
      center>
    <div class="selectmaterial-head">
      <div class="selectmaterial-left">
        <el-input  placeholder="请输入内容" v-model="name" class="input-with-select">
          <el-button  slot="append" icon="el-icon-search"></el-button>
        </el-input>
      </div>
    </div>
    <div class="dig-content">
      <div class="left-nav" >
        <div>
          <el-button type="text" v-if="types === 'VIDEO' " @click="getImgetList('VIDEO')" style="display: inline">视频</el-button>
        </div>
        <div>
          <el-button type="text" v-if="types === 'IMAGE' " @click="getImgetList('IMAGE')">图片</el-button>
        </div>
      </div>

      <div class="right-list">
        <div class="imgbox" v-for="(item,index) in materialList" @click="showdifference(index,item)" :key="index"  :class="{active:index==isActive}">
          <div class="imgbox-left">
            <img :src="item.filePath" :onerror="errorImg01" style="width: 100px" v-if="type === 'IMAGE' " />
            <video :src="item.filePath" style="width: 145px;height: 145px;"  controls v-if="type === 'VIDEO' " />
          </div>
          <div class="imgbox-right">
            <div>{{ item.title }}.{{item.fileType}}</div>
            <div>{{ item.fileSize }}|{{item.fileType}}</div>
          </div>
          <div class="time-box">
            {{ item.createTime }}
          </div>
          <label class="icon">√</label>
        </div>
      </div>
    </div>

    <span slot="footer" class="dialog-footer">
              <el-button @click="onsubmit">取 消</el-button>
              <el-button type="primary" @click="onsubmit">确 定</el-button>
            </span>
  </el-dialog>
</template>

<script>
import { urlObj } from "@/api/interface";
import { writing } from "@/api/interface/business";
import { mapState } from 'vuex'
export default {
  props: {
    types: {
      type: String,
    }
  },
  name: "Popup",
  data() {
    return{
      centerDialogVisible:true,
      name:'',
      limit: 0,
      isActive:'',
      page: 1,
      data:"",
      type:'VIDEO',
      errorImg01:'this.src="' + require('../../assets/advert_template_img.png') + '"',
      materialList:[],
    }
  },
   computed: {
     ...mapState(['hotelInfo'])
   },
  created() {
    this.hotelId = sessionStorage.getItem('hotelId')
    this.limit = sessionStorage.getItem('pageSize') * 1
  },
  mounted() {
    if (this.types != ''){
      this.getImgetList(this.types)
    }else{
      this.getImgetList(this.type)
    }
  },
  methods:{
    onsubmit(){
      if (this.data != ''){
        this.$emit("getdatalist", {path:this.data.filePath,id:this.data.id,type:this.data.type});
      }
      this.$emit('update:visible', false);
    },

    //获取素材列表
    getImgetList(type){
      this.type = type
      const url = writing.materialPage
      const param = { companyId: this.hotelInfo.id, limit: this.limit,page:this.page, type: type }
      this.$axios.get(url, param).then(res => {
        if (res.success) {
          this.materialList = res.records
        }
      })
    },
    //高亮
    showdifference(index,val){
      this.isActive = index
      this.data = val
    }

  }
}
</script>

<style scoped>
.selectmaterial-head{
  margin: 10px;
  display: flex;
}
.selectmaterial-left{
  text-align: left;
}
.selectmaterial-right{
  text-align: right;
}
.imgbox{
  display: flex;
  align-items:center;
  padding: 10px;
}
.imgbox-right{
  padding: 5px;
}
.time-box{
  line-height: 67px;
  margin-left: 20%;
}
.dig-content{
  display: flex;
  margin-top: 30px;
  border: 1px solid #dedede;
  margin-bottom: 20px;
}
.left-nav{
  width: 100px;
  text-align: center;
  border-right: 1px solid #dedede;
}
.left-nav div{
  padding: 10px 0 0 0 ;
}
.right-list{
  width: 100%;
}
.imgbox{
  display: flex;
  padding: 10px;
}
.active{
  border: 2px solid #fff;
  background: #dedede;
}
.icon{
  opacity: 0;
}
.active label {
  display: inline-block;
  position: absolute;
  right: 100px;
  width: 30px;
  text-align: center;
  background: #e6a23c85;
  height: 30px;
  line-height: 30px;
  color: #fff;
  border-radius: 100%;
  opacity: 1;
}
.imgbox-left img{
  width: 145px;
  height: 145px;
}
.imgbox-right{
  padding: 5px;
  width: 150px;
}
.time-box{
  line-height: 67px;
  margin-left: 20%;
}
</style>
